<template>
  <div>
    <div class="d-flex align-items-end">
      <h3>{{ $t('shipping') }} {{ index + 1 }}</h3>
      <h4
        v-if="shipping.notSaved"
        class=" ml-1 text-warning"
      >
        {{ $t('notSaved') }}
      </h4>
    </div>
    <b-row class="align-items-center">
      <b-col
        sm="12"
        lg="2"
      >
        <b-form-group :label="$t('minAmount')">
          <b-input-group prepend="$">
            <b-form-input
              v-model="shipping.min"
              disabled
              min="0"
              type="number"
              autocomplete="off"
              @change="
                setValue({ item: shipping, field: 'min', value: $event })
                setValue({ item: shipping, field: 'notSaved', value: true })
              "
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        lg="2"
      >
        <b-form-group :label="$t('maxAmount')">
          <b-input-group prepend="$">
            <b-form-input
              v-model="shipping.max"
              type="number"
              autocomplete="off"
              @change="changeValue($event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        lg="6"
      >
        <b-form-group :label="$t('shippingCost')">
          <b-input-group prepend="$">
            <b-form-input
              :value="shipping.price"
              type="number"
              autocomplete="off"
              @change="
                setValue({ item: shipping, field: 'price', value: $event })
                setValue({ item: shipping, field: 'notSaved', value: true })
              "
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        class="mt-1"
        sm="12"
        lg="2"
      >
        <b-button
          variant="outline-danger"
          @click="remove()"
        >
          <feather-icon icon="XIcon" />
          {{ $t('delete') }}
        </b-button>
      </b-col>
    </b-row>
    <hr>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {},
  props: {
    shipping: {
      type: Object,
      default() {
        return {}
      },
    },
    index: {
      type: Number,
      default() {
        return 0
      },
    },
  },

  computed: {
    ...mapGetters('shippings', ['shippings']),
  },
  methods: {
    ...mapMutations('shippings', {
      removeShipping: 'REMOVE_SHIPPING',
      setValue: 'SET_SHIPPING_FIELD',
    }),

    remove() {
      if (this.index === 0 && this.shippings[1]) {
        this.setValue({ item: this.shippings[1], field: 'min', value: 0 })
      } else if (this.index < this.shippings.length - 1) {
        this.setValue({
          item: this.shippings[this.index + 1],
          field: 'min',
          value: this.shippings[this.index].min,
        })
      }
      this.removeShipping({ index: this.index })
    },

    changeValue(value) {
      if (parseFloat(value) < parseFloat(this.shipping.min)) {
        this.setValue({
          item: this.shipping,
          field: 'max',
          value: String(parseFloat(this.shipping.min) + 1),
        })
        this.setValue({ item: this.shipping, field: 'notSaved', value: true })
        if (this.shippings[this.index + 1]) {
          this.setValue({
            item: this.shippings[this.index + 1],
            field: 'min',
            value: String(parseFloat(this.shipping.max) + 1),
          })
          this.setValue({
            item: this.shippings[this.index + 1],
            field: 'notSaved',
            value: true,
          })
        }
      } else {
        this.setValue({ item: this.shipping, field: 'max', value })
        this.setValue({ item: this.shipping, field: 'notSaved', value: true })
        for (let i = this.index + 1; i < this.shippings.length; i += 1) {
          this.setValue({
            item: this.shippings[i],
            field: 'min',
            value: String(parseFloat(this.shippings[i - 1].max) + 1),
          })
          this.setValue({
            item: this.shippings[i],
            field: 'notSaved',
            value: true,
          })
          if (this.shippings[i].max < this.shippings[i].min) {
            this.setValue({
              item: this.shippings[i],
              field: 'max',
              value: String(parseFloat(this.shippings[i].min) + 1),
            })
            this.setValue({
              item: this.shippings[i],
              field: 'notSaved',
              value: true,
            })
          }
        }
      }
      // if (this.shippings[this.index + 1]) {
      //   this.setValue({
      //     item: this.shippings[this.index + 1],
      //     field: 'min',
      //     value: parseFloat(value) + 1,
      //   })
      //   if (
      //     this.shippings[this.index + 1].max
      //     < this.shippings[this.index + 1].min
      //   ) {
      //     this.setValue({
      //       item: this.shippings[this.index + 1],
      //       field: 'max',
      //       value: parseFloat(this.shippings[this.index + 1].min) + 1,
      //     })
      //   }
      // }
    },
  },
}
</script>

<style></style>
