<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <div class="d-flex flex-column">
          <h1 class="font-weight-bolder">
            {{ $t('shippingsSettings') }}
          </h1>
          <span class="text-muted">{{ $t('shippingsInfo') }}</span>
        </div>
        <b-button
          variant="primary"
          @click="add()"
        >
          {{ $t('add', { type: $t('shipping') }) }}
        </b-button>
      </b-col>
    </b-row>
    <div
      v-for="(shipping, index) in shippings"
      :key="index"
    >
      <shippings-form
        :shipping="shipping"
        :index="index"
      />
    </div>
    <b-row class="mt-3">
      <b-col class="d-flex justify-content-end">
        <b-button
          v-if="shippings.length"
          variant="primary"
          @click="saveShippings()"
        >
          {{ $t('save') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ShippingsForm from '@/components/shippings/ShippingForm.vue'

export default {
  components: {
    ShippingsForm,
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters('shippings', ['shippings']),
  },

  created() {
    // this.fetchShippings({ })
    this.fetchShippings({ byBranch: this.$route.params.branchId })
  },

  methods: {
    ...mapMutations('shippings', {
      addShipping: 'ADD_SHIPPING',
    }),
    ...mapActions('shippings', ['fetchShippings', 'postShippings']),

    add() {
      if (this.shippings.length) {
        this.addShipping({
          min: parseFloat(this.shippings[this.shippings.length - 1].max) + 1,
          max: '',
          price: '',
          notSaved: true,
        })
      } else {
        this.addShipping({
          min: 0,
          max: '∞',
          price: '',
          notSaved: true,
        })
      }
    },

    saveShippings() {
      this.postShippings({
        branchId: this.$route.params.branchId,
        shippings: this.shippings,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('shippingsSaved'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style></style>
